import { ResponseSuccess } from 'api/types';

export enum NewsRCPMCampaignStatus {
  비활성 = 0,
  활성 = 1,
}

export enum CampaignStatus {
  임시저장 = 0,
  라이브 = 1,
  보상 = 2,
  정지 = 3,
  테스트 = 4,
}

/**
 * 상품 유형
 */
export enum ParticipationType {
  클릭형 = 1,
  회원가입형 = 2,
  실행형 = 6,
  설치형 = 7,
  '비디오 시청형' = 16,
  달성형 = 18,
  설치실행형 = 23,
  '비디오 클릭형' = 26,
  '비디오 달성형' = 27,
  '유튜브 구독형 (legacy)' = 28,
  'TCPA 달성형' = 29,
  'SSP 플랫폼 구매형' = 30,
  '페이스북 좋아요' = 31,
  '인스타그램 팔로우' = 32,
  '유튜브 구독형' = 33,
  '사전 예약' = 34,
  CPS = 35,
  CPC20 = 51,
  '네이버 구독형' = 36,
  '카카오톡 채널 구독형' = 37,
  '네이버 카페 구독형' = 38,
  '네이버 스토어 찜 구독형' = 39,
  '네이버 쇼핑 라이브 구독형' = 40,
  '네이버 TV 구독형' = 41,
  '네이버 포스트 구독형' = 42,
  // CPM = 43,
  'Tiktok 팔로우' = 44,
  '유튜브 시청형' = 45,
  'DAUM 뉴스 구독' = 47,

  '네이버 플레이스 저장' = 50,
  '컨텐츠 클릭형' = 52,
  '웹툰' = 53,
  '네이버 쇼핑 퀴즈' = 54,
  '네이버 플레이스 검색&저장' = 55,
  '앱 사전등록' = 56,
  '인스타그램 좋아요' = 57,
  '유튜브 구독 및 좋아요' = 58,
  CPQ = 59,
  '웹 CPC20(스크립트)' = 60,
  '멀티리워드_인스타그램 팔로우' = 61,
  // '멀티리워드_서브' = 62,
  '멀티리워드_설치형' = 63,
  '멀티리워드_실행형' = 64,
  '멀티리워드_달성형' = 65,
  '멀티리워드_인스타그램 좋아요' = 66,
  '멀티리워드_유튜브 구독형' = 67,
  '멀티리워드_유튜브 구독&좋아요' = 68,
  '멀티리워드_페이스북 좋아요' = 69,
  '멀티리워드_네이버 구독형' = 70,
  '멀티리워드_DAUM 뉴스 구독' = 71,
  '멀티리워드_카카오톡 채널 구독형' = 72,
  '멀티리워드_틱톡 팔로우' = 73,
  '동영상 시청 & 페이지 방문' = 77,

  '간편적립 AP CPC' = 48,
  '참여적립 CPC' = 49,
  '20초 랜딩형' = 51,
  '뉴스 적립형' = 46,
  'SSP_리워드_비디오' = 74,
  'RCPC 스크립트' = 75,
}

export enum SubCategoryType {
  '이벤트 참여' = 0,
  '회원가입' = 1,
  '계좌 개설' = 2,
  '보험료 조회' = 3,
  '쇼핑' = 4,
  '게임' = 5,
  '상담신청(DB수집)' = 6,
  '보험 가입' = 7,
  '비게임' = 8,
  '네이버 쇼핑' = 9,
  '네이버 플레이스' = 10,
  '간편적립' = 11,
  '게임 사전예약' = 12,
  '네이버' = 13,
  '뉴스' = 14,
  '뉴스적립' = 15,
  '리워드 비디오' = 16,
  '웹툰적립' = 17,
  '유튜브' = 18,
  '인스타그램' = 19,
  '카카오톡' = 20,
  '콘텐츠 클릭' = 21,
  '쿠팡 광고' = 22,
  '클릭형' = 23,
  '틱톡' = 24,
  '페이스북' = 25,
  'RCPC 스크립트' = 26,
  '기타' = 27,
}
/**
 * 운영체제
 */
export enum PlatformType {
  iOS = 1,
  Android = 2,
  Web = 4,
}

/**
 * 캠페인 연동 형태
 */
export enum IntegrationType {
  Outsourcing = -1,
  Unknown = 0,
  Adpopcorn = 1,
  METAPS = 2,
  CYBERZ = 3,
  EELINE = 4,
  HASOFFER = 5,
  ADBRIX = 6,
  AD_X = 7,
  APPANG = 8,
  ADCHAIN = 9,
  CLICKS_MOB = 10,
  POSTBACK = 11,
  SKP_AGENCY = 12,
  TOUCH_DOWN = 13,
  PICA = 14,
  OHC = 15,
  BUZZ_AD = 16,
  LINE = 17,
  NSWITCH = 18,
  FOCUSM = 19,
  IVE = 20,
  TNK = 21,
  POSTBACK_ADBRIX = 22,
  POSTBACK_APPSFLYER = 23,
  POSTBACK_ADJUST = 24,
  POSTBACK_SINGULAR = 25,
  POSTBACK_KOCHAVA = 26,
  POSTBACK_TUNE = 27,
  POSTBACK_ADPOPCORN_SDK = 28,
  POSTBACK_MACHINEZONE = 29,
  PINCRUX = 30,
  POSTBACK_AIRBRIDGE = 31,
  POSTBACK_BRANCH = 32,
  MINWISE = 33,
  PICKNSHARE = 34,
  POSTBACK_WISETRACKER = 35,
  LINKPRICE = 36,
  GENIEWORKS = 37,
  THEZOOOM = 38,
  FLEX = 39,
  GMO_TECH = 45,
  NSTATION = 46,
  SECRET_K = 48,
  MAF = 50,
  HELPSTORE_R = 51,
  JCURVE = 52,
  JINKOON = 53,
  POMISSION = 54,
  MezzoMedia = 55,
  ALINE = 56,
  KEYWORDLAB = 57,
  APPSOLUTION = 58,
  AP_MEZZO_MEDIA = 59,
  GOLDENDUCK = 60,
  TYR_ADS = 62,
  REWARDBOOM = 63,
  ADWIZE = 64,
}

export enum CampaignType {
  Offerwall = 1,
  DA = 2, // deprecated
}

export type CampaignTag = {
  tagNo: number;
  tagName: string;
  categoryNo: number;
  categoryName: string;
};

export type Campaign = {
  campaignStatus: CampaignStatus;
  campaignName: string;
  campaignIcon: string | null; // 캠페인 아이콘 URL
  campaignKey: string;
  visibleMediaCount: number; // 노출 매체 수
  userName: string; // 담당자
  participationType: ParticipationType;
  participationSubType: number;
  platformType: PlatformType;
  openDateTime: number; // 기간 - 시작일 (UNIXTIMESTAMP)
  closeDateTime: number; // 기간 - 종료일 (UNIXTIMESTAMP)
  campaignPrice: number; // Gross 단가
  totalCompleteCount: number; // 누적달성
  netPrice: number; // Net 단가
  mediaPrice: number; // 매체 단가
  dailyCompleteCount: number; // 일일 달성
  integrationType: IntegrationType;
  isOnSNSAbusing: boolean; // SNS 어뷰징 방지 사용 여부
  useWhiteAdidFilter: boolean; // White ADID 사용 여부
  tagList: CampaignTag[] | null; // 캠페인 태그 목록
  registDatetime: number; // 캠페인 생성 날짜 (UNIXTIMESTAMP)
  dailyGoalCount: number; // 캠페인 일일 달성 목표
  totalGoalCount: number; // 캠페인 전체 달성 목표
  campaignType: CampaignType; // 사용 X
  isGameCampaign: boolean; // 게임 유형
  isHouseAd: boolean; // House AD
  budget: number; // 전체 예산
  isUnlimitedQuantity: boolean; // 전체 예산 무제한 여부
  grossRevenue: number; // 매출
};

export type CampaignTableColumnId = keyof Pick<
  Campaign,
  | 'campaignStatus'
  | 'campaignName'
  | 'tagList'
  | 'campaignKey'
  | 'visibleMediaCount'
  | 'userName'
  | 'registDatetime'
  | 'openDateTime'
  | 'campaignPrice'
  | 'grossRevenue'
  | 'dailyCompleteCount'
  | 'totalCompleteCount'
  | 'dailyGoalCount'
  | 'isOnSNSAbusing'
  | 'useWhiteAdidFilter'
  | 'participationType'
  | 'platformType'
  | 'isGameCampaign'
  | 'isHouseAd'
  | 'netPrice'
  | 'mediaPrice'
  | 'integrationType'
  | 'totalGoalCount'
  | 'budget'
  | 'participationType'
  | 'participationSubType'
>;

/**
 * 캠페인 테이블 열 설정 정보
 */
export type CampaignTableColumnConfig = {
  key: string;
  columnList: CampaignTableColumnId[];
  isDefault: boolean;
};

/**
 * 캠페인 목록 검색 타입
 */
export enum CampaignSearchType {
  담당자명 = 0,
  캠페인명 = 1,
  캠페인키 = 2,
}

/**
 * 캠페인 목록 조회 params
 */
export type CampaignListParams = {
  searchText: string;
  searchType: CampaignSearchType;
};

/**
 * 캠페인 목록 조회 응답
 */
export type CampaignListResponseSuccess = ResponseSuccess & {
  CampaignList: Campaign[];
  Option: CampaignTableColumnConfig[] | null; // 열 맞춤 설정 목록
};

/**
 * 캠페인 상태 업데이트 요청 데이터
 */
export type UpdateCampaignStatusData = Pick<Campaign, 'campaignKey' | 'campaignStatus'>;

/**
 * 캠페인 상태 업데이트 응답
 */
export type UpdateCampaignStatusResponseSuccess = ResponseSuccess & {
  CampaignKey: Campaign['campaignKey'];
  changedStatus: Campaign['campaignStatus'];
};

/**
 * 캠페인 복사 요청 데이터
 */
export type CopyCampaignData = Pick<Campaign, 'campaignKey'> & {
  openDate: string | null; // 'YYYY-MM-DD HH:mm:ss'
  closeDate: string | null; // 'YYYY-MM-DD HH:mm:ss'
};

/**
 * 캠페인 삭제 요청 데이터
 */
export type RemoveCampaignListData = {
  campaignKeyList: Campaign['campaignKey'][];
};

/**
 * 캠페인 이름 업데이트 요청 데이터
 */
export type UpdateCampaignNameData = Pick<Campaign, 'campaignKey' | 'campaignName'>;

/**
 * 캠페인 데일리캡 업데이트 요청 데이터
 */
export type UpdateCampaignDailyGoalCountData = Pick<Campaign, 'campaignKey' | 'dailyGoalCount'>;

/**
 * 캠페인 업데이트 타입 (White ADID | SNS 어뷰징 방지)
 */
export enum CampaignStatusToggleType {
  WhiteADID = 1,
  SNSAbusing = 2,
}

/**
 * 캠페인 White ADID | SNS 어뷰징 방지 업데이트 요청 데이터
 */
export type UpdateCampaignOptionStatusData = Pick<Campaign, 'campaignKey'> & {
  toggleType: CampaignStatusToggleType;
  toggleStatus: boolean;
};

/**
 * 캠페인 운영체제 업데이트 요청 데이터
 */
export type UpdateCampaignPlatformTypeData = Pick<Campaign, 'campaignKey'> & {
  platformType: PlatformType;
};

/**
 * 캠페인 목록 조회 params
 */
export type CampaignTagListParams = {
  includeDefaultTag: boolean;
};

/**
 * 캠페인 태그 조회 응답 (수동입력 태그만)
 */
export type CampaignTagListResponseSuccess = ResponseSuccess & {
  TagList: CampaignTag[];
};

/**
 * 캠페인 태그 업데이트 요청 데이터 (캠페인 일괄 업데이트)
 */
export type UpdateMultipleCampaignTagData = {
  campaignTagNoList: CampaignTag['tagNo'][];
  campaignKeyList: Campaign['campaignKey'][];
  replaceTag: false;
};

/**
 * 캠페인 태그 업데이트 요청 데이터 (단일 캠페인 업데이트)
 */
export type UpdateSingleCampaignTagData = {
  campaignTagNoList: CampaignTag['tagNo'][];
  campaignKeyList: [Campaign['campaignKey']];
  replaceTag: true;
};

/**
 * 캠페인 태그 업데이트 요청 데이터
 */
export type UpdateCampaignTagData = UpdateMultipleCampaignTagData | UpdateSingleCampaignTagData;

/**
 * 캠페인 테이블 열 설정 업데이트 요청 데이터
 */
export type UpdateUserOptionsData = {
  userId: string;
  option: CampaignTableColumnConfig[];
};

/**
 * 캠페인 - 게임, 비게임 여부
 */
export enum SettlementType {
  게임 = 1,
  비게임 = 2,
}

/**
 * 캠페인 생성 요청 데이터
 */
export type CreateCampaignData = Pick<Campaign, 'campaignName' | 'isHouseAd' | 'platformType'> & {
  userId: string;
  participationTypeNo: ParticipationType;
  integrationTypeNo: IntegrationType;
  settlementType: SettlementType;
};

/**
 * 캠페인 생성 응답
 */
export type CreateCampaignResponseSuccess = ResponseSuccess & {
  CampaignKey: number;
};

/**
 * 캠페인 주체
 */
export enum CompanyCategoryType {
  PM = 1,
  랩사 = 2, // 랩사
  대행사 = 4,
  광고주 = 8,
}

/**
 * 캠페인 일정 정보
 */
export type NewSchedulerEvent = {
  startTime: string;
  endTime: string;
};

/**
 * 캠페인 데일리캡 정보
 */
export type DailyQuantityInfo = {
  startScheduleDate: string; // 'YYYY-MM-DD'
  endScheduleDate: string; // 'YYYY-MM-DD'
  dailyCapQuantity: string; // "300"
  totalQuantity: string; // "1,800"
};

export type Carrier = 'KT' | 'SKTelecom' | 'LGU';

/**
 * 타겟 기기 정보
 */
export type Targeting = {
  // PlatformType.Andorid
  minOsVersion_A: string | null; // "9.0.0"
  maxOsVersion_A: string | null; // "11.0.0"
  carrierTarget_A: Carrier[] | null;
  carrierDetarget_A: Carrier[] | null;
  modelTarget_A: string[] | null; // 노출 기기 목록
  modelDetarget_A: string[] | null; // 미노출 기기 목록
  // PlatformType.IOS
  minOsVersion_I: string | null;
  maxOsVersion_I: string | null;
  carrierTarget_I: Carrier[] | null;
  carrierDetarget_I: Carrier[] | null;
  modelTarget_I: string[] | null;
  modelDetarget_I: string[] | null;
};

export type CampaignDetail = {
  /**
   * 캠페인 설정 완료 여부
   */
  campaignSettingStatus: 0 | 1;

  /**
   * 타겟팅 설정 완료 여부
   */
  targetSettingStatus: 0 | 1;

  /**
   * 광고 설정 완료 여부
   */
  advertisementSettingStatus: 0 | 1;

  registDatetime: string; // '2020-03-27T17:28:31.973'
  projectManagerNo: number; // 캠페인 생성자

  // 공통
  campaignKey: string;
  platformType: PlatformType;
  integrationType: IntegrationType;
  participationType: ParticipationType;
  participationSubType: number;

  // 캠페인 설정 (최초 생성시 필드값은 0 혹은 null)
  campaignName: string;
  companyCategoryType: CompanyCategoryType | 0;
  companyNo: number; // 회사 정보
  companyManagerNo: number; // 담당자 정보
  openDate: string | null; // 기간 - 시작 ('YYYY-MM-DDTHH:mm:ss')
  closeDate: string | null; // 기간 - 종료('YYYY-MM-DDTHH:mm:ss')
  useTimeSchedule: boolean; // 일정 - 항상 게재 사용 여부

  /**
   * 캠페인 설정 - 일정
   * [
   *   [{ startTime: '00:00', endTime: '11:00' }, ...], (일)
   *   [...], (월)
   *   [...], (화)
   *   [...], (수)
   *   [...], (목)
   *   [...], (금)
   *   [...], (토)
   * ]
   */
  timeScheduleEventList: NewSchedulerEvent[][] | null;
  unlimitedQuantity: boolean; // 예산 설정 - 무제한 사용 여부
  unitPrice: number; // Gross 단가
  commissionRate: number; // 수수료
  quantity: number; // 수량
  budget: number; // 예산
  servicePercent: number; // 서비스 비율
  serviceQuantity: number; // 서비스 수량
  useDailyCap: boolean; // 데일리캡 사용 여부
  dailyQuantityInfo: DailyQuantityInfo[] | null;
  dailyCarryForward: boolean; // 미소진 데일리캡 다음날 추가 사용 여부

  // 타게팅 설정 (최초 생성시 필드값은 null)
  countryCodeList: string[]; // 노출 국가 목록 (nullable?)
  detargetCountryCodeList: string[] | null; // 미노출 국가 목록
  targetGroupIDList: string[] | null; // 타겟 그룹 선택
  targetPackageNameList: string[] | null; // 타겟 패키지 네임
  preTargetCampaign: string | null; // 선행 캠페인 참여자 타겟
  targeting: Targeting | null; // 타겟 기기 설정
  detargetGroupIDList: string[] | null; // 디타겟 그룹 선택
  relatedCampaignList: string[] | null; // 연관 캠페인 참여자 디타겟
  detargetePackageNameList: string[] | null; // 디타겟 패키지 네임 (detargete 오타?)
  participatedDetargetGroupIDList: string[] | null; // 기존 유저 디타겟 그룹 선택
  postbackDetargetGroupID: string | null;

  // 광고 설정 - 광고 상세 정보
  limitationInterval: number; // 1인당 참여 제한 - 전체 기간, 30분, 1시간 ~ 30시간 (default 전체기간) (0 ~ 31)
  limitationCount: number; // 횟수 - 0 ~ 30 (무제한 0) (default 1회)
  maxLimitationCount: number; // 최대 제한  - 0~30 선택 가능(단, 0은 무제한이며, 기본값은 0)
  limitationTime: number; // 리셋시간 -1~23 선택 가능(단, -1은 전날 참여시간 이후, 기본값은 -1)

  /**
   * 외부 연동키: 특정 연동형태에 대한 입력값
   */
  externalIntegrationKey: string | null;

  /**
   * 해당 항목은 POSTBACK 연동 형태일 경우에만 노출되며, 여타 연동 형태의 캠페인 설정 시 미노출 (IntegrationType.POSTBACK_XXX 모두 해당)
   */
  useAllPostback: boolean; // all postback 사용 여부. (default 미사용)

  /**
   * useAllPostback && IntegrationType.POSTBACK_ADBRIX
   * useAllPostback && IntegrationType.POSTBACK_XXX
   */
  allPostbackAdvApp: string | null; // TODO: (POSTBACK_ADBRIX일때 .../... 포맷 확인)

  /**
   * ParticipationType.Install인 경우에만 노출
   */
  packageName: string | null; // required (iOS스키마ID/ 패키지 네임)

  advertiseNotice: string | null; // 유의사항

  // 광고 설정 - 광고 크리에이티브
  campaignIconURL: string | null; // 캠페인 아이콘 (full url)
  commonMainImageURL: string | null; // 스페셜 오퍼, 피드 이미지 (full url)
  feedDetailImageURL: string | null; // 피드 상세 이미지 (full url)
  campaignTitle: string | null; // 오퍼월 제목
  campaignDesc: string | null; // 한 줄 설명
  participationDesc: string | null; // 오퍼월 참여 방법
  detailRewardConditionMessageList: string[] | null; // 주의 사항 (신규 오퍼월)
  participationStepMessageList: string[] | null; // 오퍼월 참여 방법 (신규 오퍼월)

  /**
   * ParticipationType.PlayYoutube을 제외한 모든 경우 사용
   */
  landingURL: string | null;

  /**
   * ParticipationType.(SNS)인 경우 사용
   */
  snsChannelName: string | null;

  /**
   * ParticipationType.PlayYoutube일 경우 사용
   */
  youtubeVideoId: string | null;
  videoId: string | null;
  videoURL: string | null;
  minimumVideoPlayingTime: string | null;

  /**
   * ParticipationType.(PlayYoutube을 제외한 모든 SNS일 경우 사용)
   */
  snsCompleteKeyword: string[] | null;

  // 매체 선택
  mediaPrice: number;
  historyMessage: string | null; // 히스토리
  isTestMode: boolean;
  mediaKeyList: number[] | null; // 매체키 (라이브)

  /**
   * MultiReward
   */
  multiRewardStepCount: string;
  multiRewardValidDays: string;
  multiRewardPreConditionDescription: string;
  multiRewardPostConditionDescription: string;
  multiRewardPreDurationDescription: string;
  multiRewardPostDurationDescription: string;
  multiRewardEventList: MultiRewardEvent[] | [];

  postbackEventList: string[] | null;
};

export type MultiRewardEvent = {
  campaignKey: string;
  event: string;
  durationDays: number;
  name: string;
  description: string;
  multiMediaPrice: number;
  multiUnitPrice: number;
  order: number;
};

/**
 * 캠페인 상세 조회 params
 */
export type CampaignDetailParams = Pick<Campaign, 'campaignKey'>;

/**
 * 캠페인 상세 조회 응답
 */
export type CampaignDetailResponseSuccess = ResponseSuccess & {
  CampaignInfo: CampaignDetail;
};

/**
 * 회사 정보
 */
export type Company = {
  CompanyNo: number;
  CompanyName: string;
  CompanyRegistNo: string;
  CompanyAddress: string;
  CompanyContactNo: string;
  CompanyFaxNo: string;
};

/**
 * 회사 목록 조회 params
 */
export type CompanyListParams = {
  companyCategoryType: CompanyCategoryType;
};

/**
 * 회사 목록 조회 응답
 */
export type CompanyListResponseSuccess = ResponseSuccess & {
  CompanyList: Company[];
};

/**
 * 담당자 정보
 */
export type Customer = {
  CustomerName: string;
  CustomerNo: number;
  CustomerDepartmentName: string | null;
  CustomerContactNo: string;
  CustomerEmailAddress: string;
};

/**
 * 담당자 목록 조회 params
 */
export type CustomerListParams = {
  companyNo: number;
};

/**
 * 담당자 목록 조회 응답
 */
export type CustomerListResponseSuccess = ResponseSuccess & {
  CustomerList: Customer[];
};

/**
 * 캠페인 상세 업데이트 타입
 */
export enum UpdateCampaignType {
  CampaignSetting = 1,
  Targeting = 2,
  AdSetting = 3,
  SelectMediaDraft = 4,
  SelectMedia = 5,
}

/**
 * 캠페인 설정 업데이트 요청 데이터 (UpdateCampaignType.CampaignSetting)
 */
export type UpdateCampaignSettingData = Pick<
  CampaignDetail,
  | 'campaignName'
  | 'companyCategoryType'
  | 'companyNo'
  | 'companyManagerNo'
  | 'openDate'
  | 'closeDate'
  | 'useTimeSchedule'
  | 'timeScheduleEventList'
  | 'unlimitedQuantity'
  | 'unitPrice'
  | 'commissionRate'
  | 'quantity'
  | 'budget'
  | 'serviceQuantity'
  | 'servicePercent'
  | 'useDailyCap'
  | 'dailyQuantityInfo'
  | 'dailyCarryForward'
  | 'projectManagerNo'
>;

/**
 * 타겟팅 업데이트 요청 데이터 (UpdateCampaignType.Targeting)
 */
export type UpdateTargetingData = Pick<
  CampaignDetail,
  | 'countryCodeList'
  | 'detargetCountryCodeList'
  | 'targetGroupIDList'
  | 'targetPackageNameList'
  | 'preTargetCampaign'
  | 'targeting'
  | 'detargetGroupIDList'
  | 'relatedCampaignList'
  | 'detargetePackageNameList'
  | 'participatedDetargetGroupIDList'
  | 'postbackDetargetGroupID'
>;

/**
 * 광고 설정 업데이트 요청 데이터(UpdateCampaignType.Targeting)
 */
export type UpdateAdSettingData = Pick<
  CampaignDetail,
  | 'maxLimitationCount'
  | 'limitationTime'
  | 'limitationInterval'
  | 'limitationCount'
  | 'externalIntegrationKey'
  | 'useAllPostback'
  | 'allPostbackAdvApp'
  | 'packageName'
  | 'advertiseNotice'
  | 'campaignIconURL' // relative path
  | 'commonMainImageURL' // relative path
  | 'feedDetailImageURL' // relative path
  | 'campaignTitle'
  | 'campaignDesc'
  | 'participationDesc'
  | 'landingURL'
  | 'snsChannelName'
  | 'youtubeVideoId'
  | 'videoId'
  | 'videoURL'
  | 'minimumVideoPlayingTime'
  | 'snsCompleteKeyword'
  | 'detailRewardConditionMessageList'
  | 'participationStepMessageList'
  | 'multiRewardStepCount'
  | 'multiRewardValidDays'
  | 'multiRewardPreConditionDescription'
  | 'multiRewardPostConditionDescription'
  | 'multiRewardPreDurationDescription'
  | 'multiRewardPostDurationDescription'
  | 'multiRewardEventList'
>;

/**
 * 매체 선택 - 임시저장 요청 데이터
 */
export type UpdateSelectMediaDraftData = Pick<
  CampaignDetail,
  'mediaPrice' | 'historyMessage' | 'commissionRate' | 'unitPrice' | 'multiRewardEventList'
>;

/**
 * 매체 선택 - 게재 요청 데이터
 */
export type UpdateSelectMediaData = UpdateSelectMediaDraftData &
  Pick<
    CampaignDetail,
    'isTestMode' | 'mediaKeyList' | 'multiRewardEventList' | 'participationType'
  >;

export type UpdateCampaignDetailDataMapper = {
  [UpdateCampaignType.CampaignSetting]: UpdateCampaignSettingData;
  [UpdateCampaignType.Targeting]: UpdateTargetingData;
  [UpdateCampaignType.AdSetting]: UpdateAdSettingData;
  [UpdateCampaignType.SelectMediaDraft]: UpdateSelectMediaDraftData;
  [UpdateCampaignType.SelectMedia]: UpdateSelectMediaData;
};

/**
 * 캠페인 상세 업데이트 요청 데이터
 */
export type UpdateCampaignDetailData<T extends UpdateCampaignType> = {
  insertionOrderType: T;
  campaignInfo: UpdateCampaignDetailDataMapper[T] & {
    campaignKey: Campaign['campaignKey'];
  };
};

/**
 * 타겟(오디언스)그룹 목록 조회 params
 */
export type AudienceListParams = Pick<Campaign, 'campaignKey'>;

/**
 * 타겟(오디언스) 그룹 정보
 */
export type TargetGroup = {
  groupID: string;
  groupName: string;
  totalCount: number;
  progressCount: number;
  campaignKey: string;
  isTargetCampaign: boolean | null; // boolean?
};

/**
 * 타겟 그룹 목록 조회 응답
 */
export type AudienceListResponseSuccess = ResponseSuccess & {
  TargetGroupList: TargetGroup[];
};

/**
 * 선행 캠페인, 연관 캠페인 정보
 */
export type CampaignMeta = {
  campaignKey: string;
  campaignName: string;
};

/**
 * 선행 캠페인 목록 조회 params
 */
export type TargetCampaignListParams = Pick<Campaign, 'campaignKey'>;

/**
 * 선행 캠페인 목록 조회 응답
 */
export type TargetCampaignListResponseSuccess = ResponseSuccess & {
  CampaignMetaList: CampaignMeta[];
};

/**
 * 비교 캠페인 조회 params
 */
export type CampaignCompareParams = Pick<Campaign, 'campaignKey'> & {
  compareVersion: number;
};

/**
 * 비교 캠페인 조회 응답
 */
export type CampaignCompareResponseSuccess = ResponseSuccess & {
  HistoryCampaignDetail: CampaignDetail;
};

/**
 * 캠페인 버전 정보
 */
export type CampaignVersion = {
  version: number;
  versionName: string;
};

/**
 * 캠페인 버전 목록 조회 params
 */
export type CampaignVersionListParams = Pick<Campaign, 'campaignKey'>;

/**
 * 캠페인 버전 목록 조회 응답
 */
export type CampaignVersionListResponseSuccess = ResponseSuccess & {
  CampaignHistoryVersion: CampaignVersion[];
};

/**
 * 삭제 캠페인 목록 조회 params
 */
export type RemovedCampaignListParams = CampaignListParams;

/**
 * 삭제 캠페인 목록 조회 응답
 */
export type RemovedCampaignListResponseSuccess = ResponseSuccess & {
  CampaignList: Campaign[];
};

/**
 * 삭제 캠페인 복구 요청 데이터
 */
export type RecoverCampaignListData = {
  campaignKeyList: Campaign['campaignKey'][];
};

/**
 * 캠페인 영구 삭제 요청 데이터
 */
export type DeleteCampaignListData = RecoverCampaignListData;

/**
 * 캠페인 조회
 */
export type SearchCampaignMetaParams = {
  search: string; // 검색할 캠페인 키 혹은 캠페인 이름
  campaignKey: string; // 제외할 자신의 캠페인 키
};

export type CampaignMetaWithNo = CampaignMeta & {
  campaignNo: number;
};

export type SearchCampaignMetaResponseSuccess = ResponseSuccess & {
  CampaignMetaList: CampaignMetaWithNo[];
};

/**
 * 캠페인 조회
 */
export type MediaListByTargetCampaignKeyParams = {
  targetCampaignKey: string; //매체 리스트를 조회할 캠페인 키
  campaignKey: string; // 제외할 자신의 캠페인 키
};

/**
 * 매체 목록 조회 응답
 */
export type MediaListByTargetCampaignKeySuccess = ResponseSuccess & {
  MediaList: MediaMeta[];
};

/**
 * 매체 단가 정보
 */
export type StandardMediaPriceMeta = {
  participationName: string;
  participationAlias: string;
  participationType: ParticipationType;
  mediaPrice: number;
  mediaRSRate: number; // double
  useRSRate: boolean;
};

/**
 * 매체 단가 목록 조회 응답
 */
export type StandardMediaPriceListResponseSuccess = ResponseSuccess & {
  MediaPriceList: StandardMediaPriceMeta[];
};

/**
 * 매체 태그 정보
 */
export type MediaTag = {
  tagNo: number;
  tagName: string;
  categoryNo: number;
  categoryName: string;
};

/**
 * 매체 태그 목록 조회 응답
 */
export type MediaTagListResponseSuccess = ResponseSuccess & {
  TagList: MediaTag[];
};

/**
 * 매체 목록 조회(검색어) params
 */
export type MediaMetaListBySearchKeyParams = {
  searchKey: string; // 매체 이름 혹은 매체 키
  filterCampaignKey?: string; //해당 캠페인 키에 연결된 매체 필터
};

/**
 * 매체 정보
 */
export type MediaMeta = {
  mediaNo: number;
  mediaKey: number;
  mediaName: string;
};

/**
 * 매체 목록 조회 응답
 */
export type MediaMetaListBySearchKeyResponseSuccess = ResponseSuccess & {
  MediaList: MediaMeta[];
};

/**
 * 매체 목록 조회(태그) params
 */
export type MediaMetaListByTagNoParams = {
  includeTagNoList: MediaTag['tagNo'][];
  excludeTagNoList: MediaTag['tagNo'][];
  filterCampaignKey?: string; //해당 캠페인 키에 연결된 매체 필터
};

/**
 * 매체 목록 조회(태그) 응답
 */
export type MediaMetaListByTagNoResponseSuccess = ResponseSuccess & {
  MediaCount: number;
  MediaList: MediaMeta[];
};

/**
 * 매체 연동형태
 */
export enum MediaIntegrationType {
  SDK = 1,
  S2S = 2,
}

/**
 * 특정 캠페인에 매칭되어 있는 매체 (캠페인 관리 -> 노출 매체)
 */
export type CampaignMedia = {
  campaignPrice: number;
  mediaName: string;
  mediaKey: number;
  openDateTime: number; // UNIXTIMESTAMP (default: 0)
  closeDateTime: number; // UNIXTIMESTAMP (default: 0)
  integrationType: MediaIntegrationType;
  platformType: PlatformType;
  mediaPrice: number; // 매체단가
  mediaTotalCompleteCount: number; // 누적 달성
  mediaDailyCompleteCount: number; // 일일 달성
  mediaDailyGoalCount: number; // 매체 데일리 캡
  isOpenToUser: boolean; // 노출 상태
  isCanComplete: boolean; // 보상 상태
  useTargeting: boolean; // 타겟팅
  useCustomLandingURL: boolean; // 커스텀 랜딩 URL 사용 여부
  customLandingURL: string; // 커스텀 랜딩 URL (nullable?)
  multiRewardEventList: Partial<MultiRewardEvent>[];
};

/**
 * 노출 매체 목록 조회 params
 */
export type CampaignMediaListParmas = Pick<Campaign, 'campaignKey'>;

/**
 * 노출 매체 목록 조회 응답
 */
export type CampaignMediaListResponseSuccess = ResponseSuccess & {
  CampaignName: string;
  CampaignStatus: CampaignStatus;
  OpenDateTime: number; // UNIXTIMESTAMP (default: 0)
  CloseDateTime: number; // UNIXTIMESTAMP (default: 0)
  MatchedMediaList: CampaignMedia[] | null;
};

/**
 * 노출 매체 단가 업데이트 요청 데이터
 */
export type UpdateCampaignMediaPriceData = Pick<Campaign, 'campaignKey'> &
  Pick<CampaignMedia, 'mediaPrice'> & {
    mediaKeyList: CampaignMedia['mediaKey'][];
  };

/**
 * 노출 매체(멀티)) 단가 업데이트 요청 데이터
 */
export type UpdateMultiCampaignMediaPriceData = Pick<Campaign, 'campaignKey'> & {
  mediaKeyList: CampaignMedia['mediaKey'][];
} & {
  multiRewardEventList: Partial<MultiRewardEvent>[];
};

/**
 * 노출 매체 데일리캡 업데이트 요청 데이터
 */
export type UpdateCampaignMediaDailyGoalData = Pick<Campaign, 'campaignKey'> &
  Pick<CampaignMedia, 'mediaKey'> & {
    dailyGoalCount: number;
  };

/**
 * 노출 매체 상태 업데이트 타입 (라이브, 보상, 정지, 노출 상태, 보상 상태, 타겟팅, 커스탬 랜딩 URL)
 */
export enum UpdateMediaStatusType {
  Live = 1,
  Reward = 2,
  Paused = 3,
  OpenUser = 4,
  OpenReward = 5,
  Targeting = 6,
  CustomLandingURL = 7,
}

/**
 * 노출 매체 상태(라이브, 보상, 정지) 업데이트 요청 데이터
 */
export type UpdateCampaignMediaListStatusData = Pick<Campaign, 'campaignKey'> & {
  mediaKeyList: CampaignMedia['mediaKey'][];
  toggleType:
    | UpdateMediaStatusType.Live
    | UpdateMediaStatusType.Reward
    | UpdateMediaStatusType.Paused;
};

/**
 * 노출 매체 노출 상태, 보상 상태 업데이트 요청 데이터
 */
export type UpdateCampaignMediaStatusData = Pick<Campaign, 'campaignKey'> &
  Pick<CampaignMedia, 'mediaKey'> & {
    toggleType: UpdateMediaStatusType.OpenUser | UpdateMediaStatusType.OpenReward;
    toggleStatus: boolean;
  };

/**
 * 노출 매체 타겟팅, 커스텀 랜딩 URL 옵션 업데이트 요청 데이터
 */
export type UpdateCampaignMediaOptionStatusData = Pick<Campaign, 'campaignKey'> &
  Pick<CampaignMedia, 'mediaKey'> & {
    toggleType: UpdateMediaStatusType.Targeting | UpdateMediaStatusType.CustomLandingURL;
    toggleStatus: boolean;
  };

/**
 * 노출 매체 커스텀 랜딩 URL 업데이트 요청 데이터
 */
export type UpdateCustomLandingURLData = Pick<Campaign, 'campaignKey'> &
  Pick<CampaignMedia, 'mediaKey'> & {
    mediaCustomLandingURL: string;
  };

/**
 * 노출 매체 노출 기간 업데이트 요청 데이터
 */
export type UpdateCampaignMediaScheduleData = Pick<Campaign, 'campaignKey'> & {
  mediaKeyList: CampaignMedia['mediaKey'][];
  openDate: string; // '2022-06-01 00:00:00'
  closeDate: string | null; // '2023-06-01 00:00:00' | null;
};

/**
 * 노출 매체 타게팅 업데이트 요청 데이터
 */
export type UpdateCampaignMediaTargetingData = Pick<Campaign, 'campaignKey'> & {
  mediaKeyList: CampaignMedia['mediaKey'][];
  toggleStatus: boolean;
};

/**
 * 노출 매체 삭제 요청 데이터
 */
export type RemoveCampaignMediaListData = Pick<Campaign, 'campaignKey'> & {
  mediaKeyList: CampaignMedia['mediaKey'][];
};

/**
 * 타 네트워크 캠페인 조회 타입
 */
export enum ExternalCampaignSearchType {
  전체 = 0,
  네트워크사 = 1,
  캠페인명 = 2,
  // TODO: 검색 타입 추가 여부 논의
  // '상품 유형' = 3,
  // 운영체제 = 4,
}

export enum ExternalCampaignStatus {
  미생성 = 0,
  임시저장 = 1,
  라이브 = 2,
  보상 = 3,
  정지 = 4,
  테스트 = 5,
}

/**
 * 타 네트워크 캠페인 조회 params
 */
export type ExternalCampaignListParams = {
  searchType: ExternalCampaignSearchType;
  searchText: string;
};

export type ExternalCampaign = {
  networkName: string;
  integrationType: IntegrationType;
  integrationKey: string;
  campaignName: string;
  campaignIcon: string;
  campaignDesc: string;
  participationDesc: string;
  platform: PlatformType;
  participationType: ParticipationType;
  participationName: null;
  packageName: string; // could be "null"
  unitPrice: number;
  openDateTime: string; // "2022-10-01T00:00:00"
  closeDateTime: string; // "2022-10-01T00:00:00"
  updateDateTime: string; // "2022-10-01T00:00:00"
  registDateTime: string; // "2022-10-01T00:00:00"
  status: ExternalCampaignStatus;
  adpopcornCampaignKey: string | null;
  campaignMainImage: string;
  landingURL: string;
};

export type ExternalCampaignListResponseSuccess = ResponseSuccess & {
  ExternalNetworkCampaignList: ExternalCampaign[];
  LastUpdateDateTime: string; // "2022-10-31T10:40:39.97"
};

/**
 * 타 네트워크 캠페인 목록 새로고침
 */
export type RefreshExternalCampaignListResponseSuccess = ResponseSuccess & {
  LastUpdateDateTime: string; // "2022-10-31T10:40:39.97"
};

/**
 * 타 네트워크 캠페인 생성 데이터
 */
export type CreateExternalCampaignData = Pick<
  ExternalCampaign,
  | 'campaignName'
  | 'packageName'
  | 'campaignDesc'
  | 'participationType'
  | 'platform'
  | 'integrationKey'
  | 'integrationType'
  | 'unitPrice'
  | 'openDateTime'
  | 'closeDateTime'
  | 'landingURL'
> & {
  campaignIconURL: ExternalCampaign['campaignIcon'];
  partiDesc: ExternalCampaign['participationDesc'];
  settlementType: SettlementType;
  campaignMainImageURL: ExternalCampaign['campaignMainImage'];
};

/**
 * 타 네트워크 캠페인 생성 데이터
 */
export type CreateExternalCampaignByIdData = Pick<
  ExternalCampaign,
  'campaignName' | 'participationType' | 'platform' | 'integrationKey' | 'integrationType'
> & {
  settlementType: SettlementType;
};

/**
 * 타 네트워크 캠페인 생성
 */
export type CreateExternalCampaignResponseSuccess = ResponseSuccess & {
  CampaignKey: number;
};

/**
 * 쇼핑 네트워크 캠페인 조회 타입
 */
export enum ShoppingCampaignSearchType {
  전체 = 0,
  네트워크사 = 1,
  캠페인명 = 2,
}

/**
 * 쇼핑 네트워크 캠페인 조회 params
 */
export type ShoppingCampaignListParams = {
  searchType: ExternalCampaignStatus;
  searchText: string;
};

export type ShoppingCampaign = {
  networkName: string;
  integrationType: IntegrationType;
  integrationKey: string;
  campaignName: string;
  campaignIcon: string;
  campaignPortraitImage: string;
  campaignLandscapeImage: string;
  campaignDesc: string | null;
  campaignCategory: string | null;
  participationDesc: string | null;
  platform: PlatformType;
  participationType: ParticipationType;
  participationName: null;
  openDateTime: string; // "2021-10-19T18:59:58.81"
  closeDateTime: string; // "2021-10-19T18:59:58.81"
  unitPrice: number;
  updateDateTime: string; // "2021-10-19T18:59:58.81",
  registDateTime: string; // "2021-10-19T18:59:58.81",
  status: ExternalCampaignStatus;
  adpopcornCampaignKey: string;
  landingURL: string;
};

export type ShoppingCampaignListResponseSuccess = ResponseSuccess & {
  ExternalCPSCampaignList: ShoppingCampaign[];
  LastUpdateDateTime: string; // "2022-10-31T10:40:39.97"
};

/**
 * 쇼핑 네트워크 캠페인 목록 새로고침
 */
export type RefreshShoppingCampaignListResponseSuccess = ResponseSuccess & {
  LastUpdateDateTime: string; // "2022-10-31T10:40:39.97"
};

/**
 * 쇼핑 네트워크 캠페인 생성 데이터
 */
export type CreateShoppingCampaignData = Pick<
  ShoppingCampaign,
  | 'campaignName'
  | 'campaignLandscapeImage'
  | 'campaignPortraitImage'
  | 'landingURL'
  | 'campaignDesc'
  | 'participationType'
  | 'integrationKey'
  | 'integrationType'
  | 'unitPrice'
  | 'openDateTime'
  | 'closeDateTime'
  | 'platform'
> & {
  campaignIconURL: ShoppingCampaign['campaignIcon'];
  partiDesc: ShoppingCampaign['participationDesc'];
  settlementType: SettlementType;
};

/**
 * 쇼핑 네트워크 캠페인 생성
 */
export type CreateShoppingCampaignResponseSuccess = ResponseSuccess & {
  CampaignKey: number;
};

export type ParticipationSubTypeParams = {
  participationType: number;
};

export type ParticipationSubTypeList = { subTypeName: string; subTypeNo: SubCategoryType }[];

export type ParticipationSubTypeListResponseSuccess = ResponseSuccess & {
  ParticipationSubTypeList: ParticipationSubTypeList;
};

/**
 * 매체 승인 상태
 */
export enum ChangeRequestStatus {
  '처리 예정' = 0,
  승인 = 1,
  반려 = 2,
}

export type CampaignChangeRequestData = {
  campaignName: string;
  campaignStatus: number;
  campaignKey: number;
  companyName: string;
  changeRequestNo: number;
  platformType: PlatformType;
  comment: string;
  changeRequestStatus: ChangeRequestStatus;
  campaignRegistDateTime: string | null;
  changeRequestDateTime: string | null;
  changeRequestCompleteDateTime: string | null;
};

export type CampaignChangeRequestListResponseSuccess = ResponseSuccess & {
  CampaignChangeRequestList: CampaignChangeRequestData[];
};

export type CampaignChangeRequestDetailInfo = {
  openDateTime: string;
  closeDateTime: string;
  budget: number;
  unitPrice: number;
  quantity: number;
  useDailyCap: boolean;

  dailyQuantityInfo: [
    {
      startScheduleDate: string;
      endScheduleDate: string;
      dailyCapQuantity: string;
      totalQuantity: string;
    }
  ];
  iconImageURL: string;
  mainImageURL: string;
  detailImageURL: string;
  landingURL: string;
  // campaignTitle: string;
  // campaignDescription: string;
};

export type CampaignRequestDetailType = CampaignChangeRequestData & {
  currentCampaignInfo: CampaignChangeRequestDetailInfo;
  requestCampaignInfo: CampaignChangeRequestDetailInfo;
};

export type CampaignChangeRequestDetailResponseSuccess = ResponseSuccess & {
  ChangeRequestDetail: CampaignRequestDetailType;
};

export type UpdateCampaignChangeRequestStatusParams = {
  changeRequestNo: number;
  changeRequestStatus: ChangeRequestStatus;
  comment: string;
};

export type UpdateCampaignChangeRequestStatusResponseSuccess = ResponseSuccess;
