import { get, post } from 'api/request';

import * as Types from './types';

export default class CampaignAPI {
  /**
   * 캠페인 목록 조회
   */
  static getCampaignList(token: string, params: Types.CampaignListParams) {
    return get<Types.CampaignListResponseSuccess, Types.CampaignListParams>(
      '/api/v1/campaign/manage/CampaignList',
      token,
      params
    );
  }

  /**
   * 캠페인 상태 업데이트
   */
  static updateCampaignStatus(token: string, data: Types.UpdateCampaignStatusData) {
    return post<Types.UpdateCampaignStatusResponseSuccess, Types.UpdateCampaignStatusData>(
      '/api/v1/campaign/manage/UpdateCampaignStatus',
      data,
      token
    );
  }

  /**
   * 캠페인 이름 업데이트
   */
  static updateCampaignName(token: string, data: Types.UpdateCampaignNameData) {
    return post('/api/v1/campaign/manage/UpdateCampaignName', data, token);
  }

  /**
   * 캠페인 데일리캡 업데이트
   */
  static updateDailyGoalCount(token: string, data: Types.UpdateCampaignDailyGoalCountData) {
    return post('/api/v1/campaign/manage/UpdateCampaignDailyGoal', data, token);
  }

  /**
   * 캠페인 White ADID | SNS 어뷰징 방지 업데이트
   */
  static updateCampaignOptionStatus(token: string, data: Types.UpdateCampaignOptionStatusData) {
    return post('/api/v1/campaign/manage/UpdateCampaignOptionStatus', data, token);
  }

  /**
   * 캠페인 운영체제 업데이트
   */
  static updateCampaignPlatform(token: string, data: Types.UpdateCampaignPlatformTypeData) {
    return post('/api/v1/campaign/manage/UpdateCampaignPlatform', data, token);
  }

  /**
   * 캠페인 태그 목록 조회
   */
  static getCampaginTagList(token: string, params: Types.CampaignTagListParams) {
    return get<Types.CampaignTagListResponseSuccess, Types.CampaignTagListParams>(
      '/api/v1/campaign/manage/CampaignTagList',
      token,
      params
    );
  }

  /**
   * 캠페인 태그 업데이트
   */
  static updateCampaignTag(token: string, data: Types.UpdateCampaignTagData) {
    return post('/api/v1/campaign/manage/UpdateCampaignTagMatch', data, token);
  }

  /**
   * 캠페인 복제
   */
  static copyCampaign(token: string, data: Types.CopyCampaignData) {
    return post('/api/v1/campaign/manage/CopyCampaign', data, token);
  }

  /**
   * 캠페인 삭제
   */
  static removeCampaignList(token: string, data: Types.RemoveCampaignListData) {
    return post('/api/v1/campaign/manage/RemoveCampaginList', data, token);
  }

  /**
   * 캠페인 테이블 열 설정 업데이트
   */
  static updateUserOptions(token: string, data: Types.UpdateUserOptionsData) {
    return post('/api/v1/campaign/manage/UpdateUserOptions', data, token);
  }

  /**
   * 캠페인 생성
   */
  static createCampaignIO(token: string, data: Types.CreateCampaignData) {
    return post<Types.CreateCampaignResponseSuccess, Types.CreateCampaignData>(
      '/api/v1/campaign/manage/CreateCampaignIO',
      data,
      token
    );
  }

  /**
   * 캠페인 상제 정보 조회
   */
  static getCampaignDetail(token: string, params: Types.CampaignDetailParams) {
    return get<Types.CampaignDetailResponseSuccess, Types.CampaignDetailParams>(
      '/api/v1/campaign/manage/CampaignDetailInfo',
      token,
      params
    );
  }

  /**
   * 회사 목록 조회
   */
  static getCompanyList(token: string, params: Types.CompanyListParams) {
    return get<Types.CompanyListResponseSuccess, Types.CompanyListParams>(
      '/api/v1/campaign/manage/CompanyList',
      token,
      params
    );
  }

  /**
   * 담당자 목록 조회
   */
  static getCustomerList(token: string, params: Types.CustomerListParams) {
    return get<Types.CustomerListResponseSuccess, Types.CustomerListParams>(
      '/api/v1/campaign/manage/CustomerList',
      token,
      params
    );
  }

  /**
   * 캠페인 상세정보 업데이트
   */
  static updateCampaignDetail<T extends Types.UpdateCampaignType>(
    token: string,
    data: Types.UpdateCampaignDetailData<T>
  ) {
    return post('/api/v1/campaign/manage/UpdateCampaignInfo', data, token);
  }

  /**
   * 타겟 그룹 목록 조회
   */
  static getAudienceList(token: string, params: Types.AudienceListParams) {
    return get<Types.AudienceListResponseSuccess, Types.AudienceListParams>(
      '/api/v1/campaign/manage/AudienceList',
      token,
      params
    );
  }

  /**
   * 선행 캠페인 목록 조회 결과
   */
  static getTargetCampaignList(token: string, params: Types.TargetCampaignListParams) {
    return get<Types.TargetCampaignListResponseSuccess, Types.TargetCampaignListParams>(
      '/api/v1/campaign/manage/TargetCampaignList',
      token,
      params
    );
  }

  /**
   * 비교 캠페인 조회
   */
  static getCampaignCompare(token: string, params: Types.CampaignCompareParams) {
    return get<Types.CampaignCompareResponseSuccess, Types.CampaignCompareParams>(
      '/api/v1/campaign/manage/CampaignCompare',
      token,
      params
    );
  }

  /**
   * 캠페인 버전 목록 조회
   */
  static getCampaignVersionList(token: string, params: Types.CampaignVersionListParams) {
    return get<Types.CampaignVersionListResponseSuccess, Types.CampaignVersionListParams>(
      '/api/v1/campaign/manage/CampaignHistoryVersion',
      token,
      params
    );
  }

  /**
   * 캠페인 목록 조회 (검색어)
   */
  static getCampaignMetaBySearch(token: string, params: Types.SearchCampaignMetaParams) {
    return get<Types.SearchCampaignMetaResponseSuccess, Types.SearchCampaignMetaParams>(
      '/api/v1/campaign/manage/SearchCampaignMeta',
      token,
      params
    );
  }

  /**
   *  캠페인에 매칭된 매체 리스트 조회
   */
  static getMediaListByTargetCampaignKey(
    token: string,
    params: Types.MediaListByTargetCampaignKeyParams
  ) {
    return get<
      Types.MediaMetaListBySearchKeyResponseSuccess,
      Types.MediaListByTargetCampaignKeyParams
    >('api/v1/campaign/manage/MediaListByTargetCampaignKey', token, params);
  }

  /**
   * 삭제 캠페인 목록 조회
   */
  static getRemovedCampaginList(token: string, params: Types.RemovedCampaignListParams) {
    return get<Types.RemovedCampaignListResponseSuccess, Types.RemovedCampaignListParams>(
      '/api/v1/campaign/manage/RemovedCampaignList',
      token,
      params
    );
  }

  /**
   * 삭제 캠페인 복구
   */
  static recoverCampaignList(token: string, data: Types.RecoverCampaignListData) {
    return post('/api/v1/campaign/manage/RecoverCampaignList', data, token);
  }

  /**
   * 캠페인 영구 삭제 (삭제 -> 영구 삭제)
   */
  static deleteCampaignList(token: string, data: Types.DeleteCampaignListData) {
    return post('/api/v1/campaign/manage/DeleteCampaignList', data, token);
  }

  /**
   * 매체 단가 목록 조회
   */
  static getStandardMediaPriceList(token: string) {
    return get<Types.StandardMediaPriceListResponseSuccess>(
      '/api/v1/campaign/manage/StandardMediaPriceList',
      token
    );
  }

  /**
   * 매체 태그 목록 조회
   */
  static getMediaTagList(token: string) {
    return get<Types.MediaTagListResponseSuccess>('/api/v1/campaign/manage/MediaTagList', token);
  }

  /**
   * 매체 목록 조회 (검색어)
   */
  static getMediaMetaListBySearchKey(token: string, params: Types.MediaMetaListBySearchKeyParams) {
    return get<Types.MediaMetaListBySearchKeyResponseSuccess, Types.MediaMetaListBySearchKeyParams>(
      '/api/v1/campaign/manage/MediaListBySearchKey',
      token,
      params
    );
  }

  /**
   * 매체 목록 조회 (태그)
   */
  static getMediaMetaListByTagNo(token: string, params: Types.MediaMetaListByTagNoParams) {
    return post<Types.MediaMetaListByTagNoResponseSuccess, Types.MediaMetaListByTagNoParams>(
      '/api/v1/campaign/manage/MediaListByTagNo',
      params,
      token
    );
  }

  /**
   * 노출 매체 목록 조회
   */
  static getCampaignMediaList(token: string, params: Types.CampaignMediaListParmas) {
    return get<Types.CampaignMediaListResponseSuccess, Types.CampaignMediaListParmas>(
      '/api/v1/campaign/manage/CampaignMediaList',
      token,
      params
    );
  }

  /**
   * 노출 매체 단가 업데이트
   */
  static updateCampaignMediaPrice(token: string, data: Types.UpdateCampaignMediaPriceData) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaPrice', data, token);
  }

  /**
   * 노출 매체 단가 업데이트
   */
  static updateMultiCampaignMediaPrice(
    token: string,
    data: Types.UpdateMultiCampaignMediaPriceData
  ) {
    return post('/api/v1/campaign/manage/UpdateMultiCampaignMediaPrice', data, token);
  }

  /**
   * 노출 매체 데일리캡 업데이트
   */
  static updateCampaignMediaDailyGoal(token: string, data: Types.UpdateCampaignMediaDailyGoalData) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaDailyGoal', data, token);
  }

  /**
   * 노출 매체 상태(라이브, 보상, 정지) 업데이트
   */
  static updateCampaignMediaListStatus(
    token: string,
    data: Types.UpdateCampaignMediaListStatusData
  ) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaListStatus', data, token);
  }

  /**
   * 노출 매체 노출 상태, 보상 상태 업데이트
   */
  static updateCampaignMediaStatus(token: string, data: Types.UpdateCampaignMediaStatusData) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaStatus', data, token);
  }

  /**
   * 노출 매체 타겟팅, 커스텀 랜딩 URL 옵션 업데이트
   */
  static updateCampaignMediaOptionStatus(
    token: string,
    data: Types.UpdateCampaignMediaOptionStatusData
  ) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaOptionStatus', data, token);
  }

  /**
   * 노출 매체 커스텀 랜딩 URL 업데이트
   */
  static updateCustomLandingURL(token: string, data: Types.UpdateCustomLandingURLData) {
    return post('/api/v1/campaign/manage/UpdateCustomLandingURL', data, token);
  }

  /**
   * 노출 매체 노출 기간 업데이트
   */
  static updateCampaignMediaSchedule(token: string, data: Types.UpdateCampaignMediaScheduleData) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaSchedule', data, token);
  }

  /**
   * 노출 매체 타게팅 업데이트
   */
  static updateCampaignMediaTargeting(token: string, data: Types.UpdateCampaignMediaTargetingData) {
    return post('/api/v1/campaign/manage/UpdateCampaignMediaTargetOptionStatus', data, token);
  }

  /**
   * 노출 매체 삭제 요청
   */
  static removeCampaignMediaList(token: string, data: Types.RemoveCampaignMediaListData) {
    return post('/api/v1/campaign/manage/RemoveCampaignMediaList', data, token);
  }

  /**
   * 타 네트워크 캠페인 조회
   */
  static getExternalCampaignList(token: string, params: Types.ExternalCampaignListParams) {
    return get<Types.ExternalCampaignListResponseSuccess, Types.ExternalCampaignListParams>(
      '/api/v1/campaign/manage/externalNetworkCampaignList',
      token,
      params
    );
  }

  /**
   * 타 네트워크 캠페인 새로고침
   */
  static refreshExternalCampaignList(token: string, params: Types.ExternalCampaignListParams) {
    return get<Types.RefreshExternalCampaignListResponseSuccess, Types.ExternalCampaignListParams>(
      '/api/v1/campaign/manage/refreshExternalNetworkCampaignList',
      token,
      params
    );
  }

  /**
   * 타 네트워크 캠페인 생성
   */
  static createExternalCampaign(token: string, data: Types.CreateExternalCampaignData) {
    return post<Types.CreateExternalCampaignResponseSuccess, Types.CreateExternalCampaignData>(
      '/api/v1/campaign/manage/CreateExternalNetworkCampaign',
      data,
      token
    );
  }

  /**
   * 타 네트워크 캠페인 생성 (GMO,메조,TYR_ADS 이후 추가될 시 default 를 해당 api 로 사용)
   */
  static createExternalCampaignById(token: string, data: Types.CreateExternalCampaignByIdData) {
    return post<Types.CreateExternalCampaignResponseSuccess, Types.CreateExternalCampaignByIdData>(
      '/api/v1/campaign/manage/CreateExternalNetworkCampaignById',
      data,
      token
    );
  }

  /**
   * 쇼핑 네트워크 캠페인 조회
   */
  static getShoppingCampaignList(token: string, params: Types.ShoppingCampaignListParams) {
    return get<Types.ShoppingCampaignListResponseSuccess, Types.ShoppingCampaignListParams>(
      '/api/v1/campaign/manage/externalCPSCampaignList',
      token,
      params
    );
  }

  /**
   * 쇼핑 네트워크 캠페인 새로고침
   */
  static refreshShoppingCampaignList(token: string, params: Types.ShoppingCampaignListParams) {
    return get<Types.RefreshShoppingCampaignListResponseSuccess, Types.ShoppingCampaignListParams>(
      '/api/v1/campaign/manage/refreshExternalCPSCampaignList',
      token,
      params
    );
  }

  /**
   * 쇼핑 네트워크 캠페인 생성
   */
  static createShoppingCampaign(token: string, data: Types.CreateShoppingCampaignData) {
    return post<Types.CreateShoppingCampaignResponseSuccess, Types.CreateShoppingCampaignData>(
      '/api/v1/campaign/manage/createExternalCPSCampaign',
      data,
      token
    );
  }

  /**
   * 타 네트워크 캠페인 조회
   */
  static getCampaignParticipationSubTypeList(
    token: string,
    params: Types.ParticipationSubTypeParams
  ) {
    return get<Types.ParticipationSubTypeListResponseSuccess, Types.ParticipationSubTypeParams>(
      '/api/v1/campaign/manage/CampaignParticipationSubTypeList',
      token,
      params
    );
  }

  static getCampaignChangeRequestList(token: string) {
    return get<Types.CampaignChangeRequestListResponseSuccess>(
      '/api/v1/campaign/manage/CampaignChangeRequestList',
      token
    );
  }

  static getCampaignChangeRequestDetail(token: string, changeRequestNo?: number) {
    return get<Types.CampaignChangeRequestDetailResponseSuccess>(
      `/api/v1/campaign/manage/CampaignChangeRequestDetail?changeRequestNo=${changeRequestNo || 0}`,
      token
    );
  }

  static UpdateCampaignChangeRequestStatus(
    token: string,
    data: Types.UpdateCampaignChangeRequestStatusParams
  ) {
    return post<Types.UpdateCampaignChangeRequestStatusResponseSuccess>(
      '/api/v1/campaign/manage//UpdateCampaignChangeRequestStatus',
      data,
      token
    );
  }
}
